.checkoutProduct {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.checkoutProduct__info {
    padding-left: 20px;
    width: 90%;
}

.checkoutProduct__info > button {
    background: #ffd249;
    border: 0px solid;
    border-radius: 2rem;
    padding: 12px;
    margin-top: 1px;
    /* border-color: #a88734 #9c7e31 #846a29; */
    color: #111;
    font-weight: 600;
}


.checkoutProduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px;
  }

.checkoutProduct__rating {
    display: flex;
  }

.checkoutProduct__title {
    font-size: 17px;
    font-weight: 800;
}