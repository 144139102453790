.payment{
    background-color: white;
}

.payment__container > h1{
    text-align: center;
    padding: 10px;
    font-weight: 400;
    background-color: rgb(234, 237, 237);
    border-bottom: 1px solid lightgray;
}

.payment__container > h1 a{
    text-decoration: none;
}

.payment__section{
    display: flex;
    padding: 20px;
    margin: 0 20px;
    gap:2rem;
    border-bottom: 1px solid lightgray;
}

.payment__title{
    flex: 0.2;
}

.payment__address, .payment__items, .payment__details{
    flex: 0.8;
}

.payment__details > form {
    max-width: 400px;
  }

  .payment__details > h3 {
    padding-bottom: 20px;
  }




  .clickable-button {
    background: #f0c14b;
    border: 0px solid;
    border-radius: 2rem;
    padding: 12px;
    margin-top: 1px;
    color: #111;
    font-weight: 600;
    cursor: pointer;
  }

  .disabled-button {
    background: #8f772e;
    border: 0px solid;
    border-radius: 2rem;
    padding: 12px;
    margin-top: 1px;
    color: #111;
    font-weight: 600;
    cursor: not-allowed;
  }
  
 