.subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    border-radius: 3px;
  }
  
  .subtotal__gift {
    display: flex;
    align-items: center;
  }
  
  .subtotal__gift > input {
    margin-right: 5px;
  }
  
  .subtotal > button {
    background: #ffd249;
    
    border: 0px solid;
    border-radius: 2rem;
    padding: 12px;
    margin-top: 1px;
    color: #111;
    font-weight: 600;
  }
