
.orders{
    margin: 2rem auto;
    width:60%;

}
.order {
    padding: 40px;
    margin: 20px 0;
    
    border: 1px solid lightgray;
    background-color: white;
    position: relative;
}

.order__id {
    position: absolute;
    top: 40px;
    right: 20px;
  }

.order__total {
    font-weight: 500;
    text-align: right;
  }
  

  .order__info {
      margin-bottom: 20px;
  }
  
  .order__items {
      display: flex;
      flex-wrap: wrap;
  }
  
  .order__item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
  }
  
  .order__item img {
      width: 100px; 
      height: auto; 
      margin-right: 20px;
  }
  
  .order__item-info {
      flex-grow: 1; 
      max-width: 60%; 
  }
