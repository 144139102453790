.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 100px 0;
}

.home__image {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.home__container {
  display: flex;
  flex-wrap: wrap; /* 让产品容器自动换行 */
  justify-content: space-between; /* 在每行上均匀分布产品 */
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

.home__row_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 12px;
  justify-content: center; 
  padding: 0 20px; 
  margin: 0 auto; 
}

.home__row {
  flex: 0 0 calc(33.33% - 20px); /* 设置每个产品容器的宽度为33.33%，减去间距 */
  margin-bottom: 20px; /* 添加底部间距 */
}
