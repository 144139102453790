.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 10px;
    padding: 20px;
    width: 90%;
    max-height: 450px;
    min-width: 100px;
    background-color: white;
    z-index: 1;
    height: 100%;
  }
  
  .product__rating {
    display: flex;
  }

  .product__imageContainer {
    height: 200px; /* 设置容器的固定高度 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
  

  
  .product__imageContainer  > img {
    max-height: 200px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 15px;
  }
  
  .product > button {
    background: #ffd249;
    width:80%;
    border: 0px solid;
    border-radius: 2rem;
    padding: 12px;
    margin-top: 1px;
    /* border-color: #a88734 #9c7e31 #846a29; */
    color: #111;
    font-weight: 600;
  }

  .product > button:hover {
    background: #e1aa05;
  
  }
  
  .product__price {
    margin-top: 5px;
  }
  
  .product__info {
    height: 100px;
    margin-bottom: auto;
  }

  .product__brand{
    font-weight: 800;
    font-size: 1.2rem;
    width: 100%;
    
    display: flex;
    justify-content: left;
    text-align: left;
    /* margin-bottom: auto; */

   
  }
